import React from "react";
import SuiteGallery from "../blocks/SuiteGalley";

import "../../styles/sections/suiteSection.scss";
import ActionButton from "../utils/ActionButton";

const EquipmentIconText = ({ equipment, children }) => {
  return (
    <li className="bi_equipment_item">
      <div className="bi_equipment_item_icone">{children}</div>
      <p className="bi_equipment_item_icone">{equipment}</p>
    </li>
  );
};

const SuiteSection = ({ suite, localeTextGetter, index, locale }) => {
  return (
    <section className={"bi_suite" + (index % 2 ? " right" : " left")}>
      <div className="bi_suite_gallery">
        <SuiteGallery
          images={suite.images}
          locale={locale}
          className={index % 2 ? " right" : " left"}
        />
      </div>
      <div className="bi_suite_card">
        <h4>{localeTextGetter(suite.title)}</h4>
        <p>{localeTextGetter(suite.description)}</p>
        <ul className="bi_suite_card_charateristics">
          {suite.characteristics.map((characteristic, index) => {
            return (
              <li key={index} className="bi_suite_card_charateristics_item">
                <p>{localeTextGetter(characteristic.title)}</p>
                <p>
                  {characteristic.quantity > 9
                    ? characteristic.quantity
                    : 0 + characteristic.quantity}
                </p>
              </li>
            );
          })}
        </ul>
        <ul className="bi_suite_card_equipments">
          {suite.equipments.map((element, index) => {
            switch (element.title) {
              case "wifi":
                return (
                  <>
                    <EquipmentIconText equipment="Wifi">
                      <svg
                        width="49"
                        height="50"
                        viewBox="0 0 49 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M48.2017 24.88C48.2017 38.2682 37.4648 49.1169 24.2259 49.1169C10.9869 49.1169 0.25 38.2682 0.25 24.88C0.25 11.4918 10.9869 0.643066 24.2259 0.643066C37.4648 0.643066 48.2017 11.4918 48.2017 24.88Z"
                          stroke="#D7D7D7"
                          stroke-width="0.5"
                        />
                        <g clip-path="url(#clip0_22_484)">
                          <path
                            d="M15.343 22.4312L16.9581 24.0637C20.9715 20.007 27.4802 20.007 31.4936 24.0637L33.1086 22.4312C28.2069 17.4767 20.2528 17.4767 15.343 22.4312ZM21.8032 28.9611L24.2258 31.4098L26.6484 28.9611C25.316 27.6061 23.1437 27.6061 21.8032 28.9611ZM18.5731 25.6962L20.1882 27.3286C22.417 25.0758 26.0347 25.0758 28.2635 27.3286L29.8785 25.6962C26.7615 22.5455 21.6983 22.5455 18.5731 25.6962Z"
                            fill="#3A3636"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_22_484">
                            <rect
                              width="19.3807"
                              height="19.5896"
                              fill="white"
                              transform="translate(14.5354 15.0852)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </EquipmentIconText>
                  </>
                );
                break;
              case "coffre":
                return (
                  <>
                    <EquipmentIconText
                      equipment={locale !== "en" ? "Coffre fort" : "Safe"}
                    >
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22.5"
                          cy="22.5"
                          r="22.25"
                          stroke="#D7D7D7"
                          stroke-width="0.5"
                        />
                        <path
                          d="M22.5001 13.7916C23.5424 13.7916 24.4298 14.1578 25.1621 14.8901C25.8944 15.6224 26.2605 16.5097 26.2605 17.552V19.452H27.6459C27.9758 19.452 28.2562 19.5675 28.4871 19.7984C28.718 20.0293 28.8334 20.3097 28.8334 20.6395V29.2291C28.8334 29.559 28.718 29.8394 28.4871 30.0703C28.2562 30.3012 27.9758 30.4166 27.6459 30.4166H17.3542C17.0244 30.4166 16.744 30.3012 16.5131 30.0703C16.2822 29.8394 16.1667 29.559 16.1667 29.2291V20.6395C16.1667 20.3097 16.2822 20.0293 16.5131 19.7984C16.744 19.5675 17.0244 19.452 17.3542 19.452H18.7397V17.552C18.7397 16.5097 19.1058 15.6224 19.8381 14.8901C20.5704 14.1578 21.4577 13.7916 22.5001 13.7916ZM22.5001 14.9791C21.7876 14.9791 21.1806 15.2298 20.6792 15.7312C20.1779 16.2326 19.9272 16.8395 19.9272 17.552V19.452H25.073V17.552C25.073 16.8395 24.8223 16.2326 24.3209 15.7312C23.8195 15.2298 23.2126 14.9791 22.5001 14.9791ZM17.3542 29.2291H27.6459V20.6395H17.3542V29.2291ZM22.5001 23.4104C22.0779 23.4104 21.7183 23.572 21.4214 23.8953C21.1246 24.2185 20.9761 24.5781 20.9761 24.9739C20.9761 25.3829 21.1246 25.7326 21.4214 26.0229C21.7183 26.3132 22.0779 26.4583 22.5001 26.4583C22.9223 26.4583 23.2819 26.3132 23.5787 26.0229C23.8756 25.7326 24.024 25.3829 24.024 24.9739C24.024 24.5781 23.8756 24.2185 23.5787 23.8953C23.2819 23.572 22.9223 23.4104 22.5001 23.4104ZM17.3542 20.6395V29.2291V20.6395Z"
                          fill="black"
                        />
                      </svg>
                    </EquipmentIconText>
                  </>
                );
                break;
              case "kitchenette":
                return (
                  <>
                    <EquipmentIconText
                      equipment={
                        locale === "en" ? "Kitchenette" : "Kitchenette"
                      }
                    >
                      <svg
                        width="49"
                        height="50"
                        viewBox="0 0 49 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M48.3836 24.9137C48.3836 38.3019 37.6467 49.1506 24.4077 49.1506C11.1688 49.1506 0.431885 38.3019 0.431885 24.9137C0.431885 11.5255 11.1688 0.676758 24.4077 0.676758C37.6467 0.676758 48.3836 11.5255 48.3836 24.9137Z"
                          stroke="#D7D7D7"
                          stroke-width="0.5"
                        />
                        <g clip-path="url(#clip0_2_2)">
                          <path
                            d="M30.3294 16.3977L19.5623 16.3887C18.5754 16.3887 17.7678 17.1958 17.7678 18.2025V32.7133C17.7678 33.7109 18.5754 34.5271 19.5623 34.5271H30.3294C31.3164 34.5271 32.1239 33.7109 32.1239 32.7133V18.2025C32.1239 17.1958 31.3164 16.3977 30.3294 16.3977ZM30.3294 32.7133H19.5623V24.5328H30.3294V32.7133ZM30.3294 22.7371H19.5623V18.2025H30.3294V22.7371ZM21.3568 19.1094H23.1513V21.8302H21.3568V19.1094ZM21.3568 25.4579H23.1513V29.9925H21.3568V25.4579Z"
                            fill="#3A3636"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2_2">
                            <rect
                              width="21.5341"
                              height="21.7662"
                              fill="white"
                              transform="translate(14.179 14.5747)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </EquipmentIconText>
                  </>
                );
                break;
              case "ecran-plat":
                return (
                  <>
                    <EquipmentIconText
                      equipment={locale === "en" ? "TV Screen" : "Écran plat"}
                    >
                      <svg
                        width="49"
                        height="50"
                        viewBox="0 0 49 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M48.3836 24.88C48.3836 38.2682 37.6467 49.1169 24.4077 49.1169C11.1688 49.1169 0.431885 38.2682 0.431885 24.88C0.431885 11.4918 11.1688 0.643066 24.4077 0.643066C37.6467 0.643066 48.3836 11.4918 48.3836 24.88Z"
                          stroke="#D7D7D7"
                          stroke-width="0.5"
                        />
                        <g clip-path="url(#clip0_2_2)">
                          <path
                            d="M32.2138 18.0781H17.6783C16.79 18.0781 16.0632 18.8127 16.0632 19.7106V29.5054C16.0632 30.4032 16.79 31.1378 17.6783 31.1378H21.7159V32.7703H28.1762V31.1378H32.2138C33.1021 31.1378 33.8208 30.4032 33.8208 29.5054L33.8289 19.7106C33.8289 18.8127 33.1021 18.0781 32.2138 18.0781ZM32.2138 29.5054H17.6783V19.7106H32.2138V29.5054Z"
                            fill="#3A3636"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2_2">
                            <rect
                              width="19.3807"
                              height="19.5896"
                              fill="white"
                              transform="translate(15.2556 15.6294)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </EquipmentIconText>
                  </>
                );
                break;
              case "dressing":
                return (
                  <>
                    <EquipmentIconText
                      equipment={locale === "en" ? "Dressing" : "Dressing"}
                    >
                      <svg
                        width="49"
                        height="50"
                        viewBox="0 0 49 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M48.3836 24.9471C48.3836 38.3354 37.6467 49.1841 24.4077 49.1841C11.1688 49.1841 0.431885 38.3354 0.431885 24.9471C0.431885 11.5589 11.1688 0.710205 24.4077 0.710205C37.6467 0.710205 48.3836 11.5589 48.3836 24.9471Z"
                          stroke="#D7D7D7"
                          stroke-width="0.5"
                        />
                        <path
                          d="M33.4519 28.6746L25.3497 22.5324V21.6659C26.9042 21.1993 27.9877 19.5995 27.6391 17.8092C27.3941 16.5617 26.4143 15.5237 25.1802 15.2381C23.0321 14.7429 21.1102 16.3903 21.1102 18.4853H22.9944C22.9944 17.6949 23.6257 17.0569 24.4076 17.0569C25.1896 17.0569 25.8208 17.6949 25.8208 18.4853C25.8208 19.2852 25.1707 19.9327 24.3794 19.9137C23.8706 19.9042 23.4655 20.3422 23.4655 20.8565V22.5324L15.3633 28.6746C14.6379 29.2269 15.0241 30.3887 15.9286 30.3887H24.4076H32.8867C33.7911 30.3887 34.1774 29.2269 33.4519 28.6746ZM18.7549 28.4841L24.4076 24.1989L30.0603 28.4841H18.7549Z"
                          fill="#3A3636"
                        />
                      </svg>
                    </EquipmentIconText>
                  </>
                );
                break;
              case "cafe-et-the":
                return (
                  <>
                    <EquipmentIconText
                      equipment={
                        locale === "en" ? "Coffee and Tea" : "Café et Thé"
                      }
                    >
                      <svg
                        width="45"
                        height="45"
                        viewBox="0 0 45 45"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="22.5"
                          cy="22.5"
                          r="22.25"
                          stroke="#D7D7D7"
                          stroke-width="0.5"
                        />
                        <path
                          d="M28.2222 21.75H27.4722V22.5V25.2857C27.4722 26.9117 26.1295 28.25 24.4444 28.25H18.7778C17.0927 28.25 15.75 26.9117 15.75 25.2857V16.75H30.1111C30.7573 16.75 31.25 17.2526 31.25 17.8571V20.6429C31.25 21.2406 30.7548 21.75 30.1111 21.75H28.2222ZM30.1111 21.3929H30.8611V20.6429V17.8571V17.1071H30.1111H28.2222H27.4722V17.8571V20.6429V21.3929H28.2222H30.1111Z"
                          stroke="#3A3636"
                          stroke-width="1.5"
                        />
                      </svg>
                    </EquipmentIconText>
                  </>
                );
                break;
              case "climatisation":
                return (
                  <EquipmentIconText
                      equipment={
                        locale === "en" ? "Air conditioning" : "Climatisation"
                      }
                    >
                                        <svg
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="22.5"
                      cy="22.5"
                      r="22.25"
                      stroke="#D7D7D7"
                      stroke-width="0.5"
                    />
                    <path
                      d="M21.9062 30.4167V26.5573L18.9572 29.5063L18.1062 28.675L21.9062 24.875V23.0938H20.1249L16.4041 26.8146L15.5333 26.0032L18.4426 23.0938H14.5833V21.9063H18.4426L15.4739 18.9375L16.3249 18.0865L20.1249 21.9063H21.9062V20.1052L18.1853 16.3844L19.0166 15.5136L21.9062 18.423V14.5834H23.0937V18.423L26.0624 15.4542L26.8937 16.3052L23.0937 20.1052V21.9063H24.8947L28.6353 18.1657L29.4864 18.9969L26.577 21.9063H30.4166V23.0938H26.577L29.5062 26.0428L28.6947 26.8938L24.8947 23.0938H23.0937V24.875L26.8937 28.6948L26.0822 29.5459L23.0937 26.5573V30.4167H21.9062Z"
                      fill="black"
                    />
                  </svg>
                    </EquipmentIconText>
                );
                break;
              default:
                return (
                  <>
                    <EquipmentIconText equipment="Wifi">
                      <svg
                        width="49"
                        height="50"
                        viewBox="0 0 49 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M48.2017 24.88C48.2017 38.2682 37.4648 49.1169 24.2259 49.1169C10.9869 49.1169 0.25 38.2682 0.25 24.88C0.25 11.4918 10.9869 0.643066 24.2259 0.643066C37.4648 0.643066 48.2017 11.4918 48.2017 24.88Z"
                          stroke="#D7D7D7"
                          stroke-width="0.5"
                        />
                        <g clip-path="url(#clip0_22_484)">
                          <path
                            d="M15.343 22.4312L16.9581 24.0637C20.9715 20.007 27.4802 20.007 31.4936 24.0637L33.1086 22.4312C28.2069 17.4767 20.2528 17.4767 15.343 22.4312ZM21.8032 28.9611L24.2258 31.4098L26.6484 28.9611C25.316 27.6061 23.1437 27.6061 21.8032 28.9611ZM18.5731 25.6962L20.1882 27.3286C22.417 25.0758 26.0347 25.0758 28.2635 27.3286L29.8785 25.6962C26.7615 22.5455 21.6983 22.5455 18.5731 25.6962Z"
                            fill="#3A3636"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_22_484">
                            <rect
                              width="19.3807"
                              height="19.5896"
                              fill="white"
                              transform="translate(14.5354 15.0852)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </EquipmentIconText>
                  </>
                );
                break;
            }
          })}
        </ul>
        <ActionButton type="external">
          {locale === "en" ? "Book" : "Réserver"}
        </ActionButton>
      </div>
    </section>
  );
};

export default SuiteSection;
