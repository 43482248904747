import React from "react";
import { Helmet } from 'react-helmet';

import gsap from 'gsap';
import imagesLoaded from "imagesloaded";

import { graphql } from "gatsby";
import { createLocaleTextGetter } from "../utils";
import SuiteSection from "../components/sections/suiteSection";

import '../styles/pages/suites.scss';

const LesSuitesPage = ({ data, pageContext }) => {
  const localeTextGetter = createLocaleTextGetter(pageContext.locale);

  let splittedData = [];
  React.useEffect(()=>{
    const textAnimation = require("../utils/textAnimation.js").default;
    if(window.innerWidth > 769){
      let tl = gsap.timeline({
      });
      tl.set("main", { autoAlpha: 0 });
      imagesLoaded(".bi_suites_header", () => {
        splittedData = textAnimation.splitAllText();

        tl.set("main", { autoAlpha: 1});
        tl.pause();
        setTimeout(() => {
          tl.play();
        }, 500);
        textAnimation.splitTitleApparition(
          splittedData.splittedTitles.find((el) => el.id === "h1").chars,
          tl
        );
        textAnimation.splitBlockApparition(
          splittedData.splittedBlocks.find((el) => el.id === "header").lines.lines,
          "-=0.5",
          "word",
          tl
        );
      });

    }
  }, [])
  return (
    <main className="bi_suites">
            <Helmet htmlAttributes={{ lang: pageContext.locale }}>
        <title>
          {pageContext.locale === "fr"
            ? "Les Suites | Les Suites de l'île Barbe"
            : "The Suites | The Suites of Île Barbe"}
        </title>
        <meta
          type="description"
          content={
            pageContext.locale === "fr"
              ? "Découvrez nos différentes suites"
              : "Discover our different suites"
          }
        />
      </Helmet>
      <div className="bi_suites_header">
        <h1 data-splitted-title data-s-tag="h1">{localeTextGetter(data.sanitySuitesDocumentType.title)}</h1>
        <p data-splitted-block data-s-tag="header">{localeTextGetter(data.sanitySuitesDocumentType.description)}</p>
      </div>
      <div className="bi_suites_content">
        {data.sanitySuitesDocumentType.suites.map((suite, index) => {
          return (
            <SuiteSection
              key={index}
              index={index}
              suite={suite}
              localeTextGetter={localeTextGetter}
              locale={pageContext.locale}
            />
          );
        })}
      </div>
    </main>
  );
};

export const pageQuery = graphql`
  query SuitesQuery{
    sanitySuitesDocumentType {
      description {
        en
        fr
      }
      suites {
        title {
          en
          fr
        }
        images {
          Image {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alt {
            en
            fr
          }
        }
        equipments {
          title
        }
        description {
          en
          fr
        }
        characteristics {
          quantity
          title {
            en
            fr
          }
        }
      }
      title {
        en
        fr
      }
    }
  }
`;

export default LesSuitesPage;
