import React, { useState, useRef, useEffect } from "react";
import imagesLoaded from "imagesloaded";
import { createPortal } from "react-dom";
import NavButton from "../utils/NavButton";
import { GatsbyImage } from "gatsby-plugin-image";
import gsap from "gsap";

import "../../styles/components/blocks/SuiteGallery.scss";

const hideAllNonActiveSlider = (imagesDOM, first, secondary) => {
  gsap.set(
    imagesDOM.filter((x, index) => {
      if (secondary) {
        return x !== first && x !== secondary;
      } else {
        return x !== first;
      }
    }),
    { zIndex: 0, display: "none" }
  );
};
// This is the main  Gallery Component that will be used in the Suite
const SuiteGallery = ({ images, locale }) => {
  const [galleryOPen, setGalleryOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  let imagesDOM = useRef([]);

  const setNextImage = () => {
    if (isAnimating !== true) {
      setIsAnimating(true);
      if (currentImage < images.length - 1) {
        let nextImage = currentImage + 1;
        hideAllNonActiveSlider(
          imagesDOM.current,
          imagesDOM.current[currentImage],
          imagesDOM.current[nextImage]
        );
        // rendre visible l'image suivante avec un zIndex de 10
        // hide all images except the next one and the current one
        console.log(
          imagesDOM.current.filter((x, index) => {
            return (
              x !== imagesDOM.current[currentImage] &&
              x !== imagesDOM.current[nextImage]
            );
          }),
          ": filtered array : ",
          imagesDOM.current
        );
        console.log(
          imagesDOM.current[currentImage],
          imagesDOM.current[nextImage]
        );
        gsap.set(imagesDOM.current[currentImage], {
          zIndex: 5,
          display: "block",
        });
        gsap.fromTo(
          imagesDOM.current[nextImage],
          { zIndex: 10, opacity: 1, translateX: "100vw" },
          {
            zIndex: 10,
            display: "block",
            opacity: 1,
            translateX: 0,
            duration: 1,
            onComplete: () => {
              hideAllNonActiveSlider(
                imagesDOM.current,
                imagesDOM.current[currentImage],
                imagesDOM.current[nextImage]
              );
              setIsAnimating(false);
            },
          }
        );
        setCurrentImage(nextImage);
      } else {
        let nextImage = 0;
        // rendre visible l'image suivante avec un zIndex de 10
        hideAllNonActiveSlider(imagesDOM.current, imagesDOM.current[nextImage]);
        console.log(
          imagesDOM.current.filter((x, index) => {
            return (
              x !== imagesDOM.current[currentImage] &&
              x !== imagesDOM.current[nextImage]
            );
          }),
          ": filtered array : ",
          imagesDOM.current
        );
        console.log(
          imagesDOM.current[currentImage],
          imagesDOM.current[nextImage]
        );
        gsap.set(imagesDOM.current[currentImage], {
          zIndex: 5,
          display: "block",
        });
        gsap.fromTo(
          imagesDOM.current[nextImage],
          { zIndex: 10, opacity: 1, translateX: "100vw" },
          {
            zIndex: 10,
            display: "block",
            opacity: 1,
            translateX: 0,
            duration: 1,
            onComplete: () => {
              hideAllNonActiveSlider(
                imagesDOM.current,
                imagesDOM.current[nextImage]
              );
              setIsAnimating(false);
            },
          }
        );
        setCurrentImage(nextImage);
      }
    }
  };
  const setPrevImage = () => {
    if (isAnimating !== true) {
      setIsAnimating(true);
      if (currentImage > 0) {
        // create timeline firts hide current image and after show prev image
        let prevImage = currentImage - 1;
        hideAllNonActiveSlider(imagesDOM, imagesDOM.current[prevImage]);
        gsap.set(imagesDOM.current[currentImage], {
          zIndex: 5,
          display: "block",
        });
        gsap.fromTo(
          imagesDOM.current[prevImage],
          { zIndex: 10, opacity: 1, translateX: "-100vw" },
          {
            zIndex: 10,
            display: "block",
            opacity: 1,
            translateX: 0,
            duration: 1,
            onComplete: () => {
              hideAllNonActiveSlider(
                imagesDOM.current,
                imagesDOM.current[prevImage]
              );
              setIsAnimating(false);
            },
          }
        );
        setCurrentImage(prevImage);
      } else {
        let prevImage = images.length - 1;
        hideAllNonActiveSlider(
          imagesDOM.current,
          imagesDOM.current[currentImage],
          imagesDOM.current[prevImage]
        );
        gsap.set(imagesDOM.current[currentImage], {
          zIndex: 5,
          display: "block",
        });
        gsap.fromTo(
          imagesDOM.current[prevImage],
          { zIndex: 10, opacity: 1, translateX: "-100vw" },
          {
            zIndex: 10,
            display: "block",
            opacity: 1,
            translateX: 0,
            duration: 1,
            onComplete: () => {
              hideAllNonActiveSlider(
                imagesDOM.current,
                imagesDOM.current[currentImage],
                imagesDOM.current[prevImage]
              );
              setIsAnimating(false);
            },
          }
        );
        setCurrentImage(prevImage);
      }
    }
  };

  const initSlider = () => {
    gsap.set(imagesDOM.current, { zIndex: 0, display: "none" });
    gsap.set(imagesDOM.current[currentImage], { zIndex: 10, display: "block" });
  };

  React.useEffect(() => {
    initSlider();
  }, []);

  return (
    <>
      <div className="suite-full">
        <p>{locale === "en" ? "Full Screen" : "Plein écran"}</p>
        <NavButton
          type="full"
          state={galleryOPen}
          setState={setGalleryOpen}
        ></NavButton>
      </div>
      <div className="suite-gallery">
        {images
          ? images.map((image, index) => {
              // Return picture with a link to full screen
              return (
                <div
                  index={index}
                  className={index === currentImage ? "show" : ""}
                  ref={(el) => (imagesDOM.current[index] = el)}
                >
                  <GatsbyImage
                    key={index}
                    image={image.Image.asset.gatsbyImageData}
                    breakpoints={[768, 1440]}
                  />
                </div>
              );
            })
          : null}
      </div>
      <div className="suite-fil">
        <SuiteDraggableFil
          currentImage={currentImage}
          images={images}
          setPrevImage={setPrevImage}
          setNextImage={setNextImage}
          setCurrentImage={setCurrentImage}
        />
      </div>
      {
        /* Render dinamicaly the compoonent SuiteGallery Full in Portal if GalleryOpen is true  */
        galleryOPen ? (
          <SuiteGalleryFull
            images={images}
            currentImage={currentImage}
            locale={locale}
            galleryOPen={galleryOPen}
            setGalleryOpen={setGalleryOpen}
          />
        ) : null
      }
    </>
  );
};

// This is the Gallerry Component when the user is in fullscreen
const SuiteGalleryFull = ({
  images,
  currentImage,
  locale,
  setGalleryOpen,
  galleryOPen,
}) => {
  const [currentImageFull, setCurrentImageFull] = useState(currentImage);
  const currentImageFullRef = useRef(currentImage);
  // Faire une animation d'apparition du full screen
  const openGalleryFull = () => {
    let tl = gsap.timeline();
    tl.fromTo(
      ".bi_suite_gallery_full",
      { opacity: 0, height: 0, display: "block" },
      { opacity: 1, height: "100vh", display: "block", duration: 0.5 }
    );

    imagesLoaded(".bi_suite_gallery_full", () => {
      // faire apparaitre chaque image en opacité de 1 et un stagger de 0.25
      tl.fromTo(
        ".bi_suite_gallery_full_main",
        { opacity: 0 },
        { delay: 0.25, opacity: 1, duration: 0.5 }
      );
      tl.fromTo(
        ".bi_suite_gallery_full_fil .gatsby-image-wrapper",
        { opacity: 0, translateY: 10 },
        {
          opacity: 1,
          translateY: 0,
          delay: 0.25,
          stagger: {
            each: 0.1,
            from: "center",
          },
        }
      );
    });
  };
  const closeGalleryFull = () => {
    let tl = gsap.timeline({
      onComplete: () => {
        setGalleryOpen(false);
      },
    });
    tl.fromTo(
      ".bi_suite_gallery_full_fil .gatsby-image-wrapper",
      {
        opacity: 1,
        translateY: 0,
      },
      {
        opacity: 0,
        translateY: 10,
        stagger: {
          each: 0.1,
          from: "center",
        },
      }
    );
    tl.fromTo(
      ".bi_suite_gallery_full_main",
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.5,
      }
    );
    tl.fromTo(
      ".bi_suite_gallery_full",
      {
        opacity: 1,
        height: "100vh",
      },
      {
        opacity: 0,
        height: 0,
        duration: 0.5,
      }
    );
  };

  const setImageFull = (index) => {
    const imageAnimation = (start, end, sens) => {
      let images = document.querySelectorAll(
        ".bi_suite_gallery_full_main > div"
      );
      let tl = gsap.timeline();
      gsap.killTweensOf(images);
      // translate current image to the sens of the next image to be displayed
      tl.set(images[start], { zIndex: 5 });
      tl.to(images[start], { opacity: 0 });
      // translate next image to the sens of the current image to be displayed
      tl.fromTo(
        images[end],
        { translateX: sens * 100, autoAlpha: 0, display: "block", zIndex: 10 },
        {
          translateX: 0,
          autoAlpha: 1,
          display: "block",
          onComplete: () => {
            console.log(Array(images));
            hideAllNonActiveSlider([...images], images[end], images[start]);
            setCurrentImageFull(end);
          },
        }
      );
    };

    if (currentImageFull > index) {
      imageAnimation(currentImageFull, index, -1);
    } else if (currentImageFull < index) {
      imageAnimation(currentImageFull, index, 1);
    }
  };

  useEffect(() => {
    if (galleryOPen) {
      openGalleryFull();
    } else {
      closeGalleryFull();
    }
  }, [galleryOPen]);

  if (images.length > 0) {
    return createPortal(
      <div className="bi_suite_gallery_full" ref={currentImageFullRef}>
        <div className="bi_suite_gallery_full_close">
          <p>{locale === "en" ? "Close" : "Fermer"}</p>
          <NavButton
            type="full"
            state={galleryOPen}
            setState={closeGalleryFull}
          ></NavButton>
        </div>
        <div className="bi_suite_gallery_full_main">
          {images.map((image, index) => {
            return (
              <div
                index={index}
                className={index === currentImageFull ? "show" : ""}
                key={index}
              >
                <GatsbyImage
                  key={index}
                  image={image.Image.asset.gatsbyImageData}
                  breakpoints={[768, 1440]}
                />
              </div>
            );
          })}
        </div>
        <div className="bi_suite_gallery_full_fil">
          {images.map((image, index) => {
            return (
              <div
                index={index}
                className={index === currentImageFull ? "show" : ""}
                onClick={() => setImageFull(index)}
                key={index}
              >
                <GatsbyImage
                  key={index}
                  image={image.Image.asset.gatsbyImageData}
                  breakpoints={[768, 1440]}
                />
              </div>
            );
          })}
        </div>
      </div>,
      document.body
    );
  } else {
    return createPortal(
      <div className="bi_suite_gallery_full">
        <p>Malheuresement il n'ya pas d'images dans cette gallerie !</p>
      </div>,
      document.body
    );
  }
};

// This is the component who controls the main Gallery and indicate to the user wher we are in the gallery
const SuiteDraggableFil = ({
  images,
  currentImage,
  setPrevImage,
  setNextImage,
  setCurrentImage,
}) => {
  // Create a draggable images slider
  let slider = useRef();
  let innerSlider = useRef();
  let imagesDOM = useRef([]);
  let navButtons = useRef();
  const [pressed, setPressed] = useState(false);
  let animating = false;
  let startX;
  let x;

  const onMouseDown = (e) => {
    setPressed(true);

    startX = e.pageX - innerSlider.current.offsetLeft;
    slider.current.style.cursor = "grabbing";
  };

  const onMouseEnter = () => {
    slider.current.style.cursor = "grab";
  };

  const onMouseUp = () => {
    setPressed(false);
    slider.current.style.cursor = "grab";
  };

  const onMouseMove = (e) => {
    e.preventDefault();

    if (pressed && !animating) {
      x = e.pageX;
      gsap.set(innerSlider.current, { left: x - startX + "px" });

      slider.current.style.cursor = "grabbing";
    }
    // Vérifier visibilité des images
    imagesDOM.current.forEach((image, index) => {
      verifyImageIsVisible(image, index);
    });
  };

  const verifyImageIsVisible = (element, index) => {
    const rootElement = slider.current;

    // Vérifier si l'élément est entierement visible dans le cadre de rootElement
    const isVisible = (element) => {
      const elementPosition = element.getBoundingClientRect();
      const rootElementPosition = rootElement.getBoundingClientRect();
      return (
        elementPosition.left >= rootElementPosition.left &&
        elementPosition.right <= rootElementPosition.right
      );
    };

    const isPerfectlyCentered = (element) => {
      let center = rootElement.offsetWidth / 2;
      let elementCenter = element.offsetLeft + element.offsetWidth / 2;
      let difference = center - elementCenter;
      return Math.abs(difference) < 10;
    };
    if (isVisible(element) && !pressed && !isPerfectlyCentered(element)) {
      element.classList.add("visible");

      // Set currentImage to the index of the element
      if (imagesDOM.current.indexOf(element) === currentImage) {
      } else {
        setCurrentImage(imagesDOM.current.indexOf(element));
      }

      let setImageOnCenter = () => {
        let center = rootElement.offsetWidth / 2;
        let elementCenter = element.offsetLeft + element.offsetWidth / 2;
        let difference = center - elementCenter;
        gsap.to(innerSlider.current, {
          left: difference + "px",
          duration: 0.5,
          onStart: () => {
            animating = true;
          },
          onComplete: () => {
            animating = false;
          },
        });
      };

      // Set image on center
      setImageOnCenter();
    } else {
      element.classList.remove("visible");
    }
  };

  // When currentImage change, set the slider to the currentImage
  useEffect(() => {
    if (currentImage !== null && !animating) {
      let element = imagesDOM.current[currentImage];
      let center = slider.current.offsetWidth / 2;
      let elementCenter = element.offsetLeft + element.offsetWidth / 2;
      let difference = center - elementCenter;
      // Stop current tween
      gsap.killTweensOf(innerSlider.current);
      gsap.to(innerSlider.current, {
        left: difference + "px",
        duration: 0.5,
        onStart: () => {
          animating = true;
        },
        onComplete: () => {
          animating = false;
        },
      });
    }
  }, [currentImage]);

  return (
    <div
      className={
        "suite-draggable-fil" +
        (animating ? " isAnimating" : "") +
        (pressed ? " isPressed" : "")
      }
      ref={slider}
    >
      <div className="suite-draggable-fil_gallery" ref={innerSlider}>
        {images
          ? images.map((image, index) => {
              return (
                <div
                  index={index}
                  className={index === currentImage ? "show" : ""}
                  key={index}
                  ref={(el) => (imagesDOM.current[index] = el)}
                >
                  <GatsbyImage
                    key={index}
                    image={image.Image.asset.gatsbyImageData}
                    breakpoints={[768, 1440]}
                  />
                </div>
              );
            })
          : null}
      </div>
      <div className="suite-draggable-fil_fil" ref={navButtons}>
        <NavButton state={false} setState={setPrevImage} />
        <NavButton state={true} setState={setNextImage} />
      </div>
    </div>
  );
};
export default SuiteGallery;
